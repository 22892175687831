<template>
  <div class="card" style="min-height: calc(100vh - 180px)">
    <div class="bg-blue-light p-1">
      <TitleButton
        btnTitle="List"
        :showBtn="true"
        :showAddNew="false"
        title="Add Production Entry"
        @onClickCloseButton="navigateToListPage"
      />
      <div class="row gy-1">
        <div class="col-md-4 col-12">
          <label class="form-label" for="product_costing_master_id">Product Costing</label>
          <vField
            v-model="formData.product_costing_master_id"
            name="product_costing_master_id"
            type="text"
            class="form-control d-none"
          />
          <AsyncSelect
            placeholder="Select Product Costing"
            v-model="productCosting"
            :api-service="fetchProductCosting"
          />
        </div>
        <div class="col-md-4 col-12">
          <label for="colFormLabel" class="form-label">Batch No: </label>
          <input
            class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
            tabindex="1"
            type="text"
            placeholder="Batch No"
            v-model="formData.batch_no"
          >
        </div>
        <div class="col-md-4 col-12">
          <label for="colFormLabel" class="form-label">Manufacturing Date: </label>
          <input
            class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
            tabindex="1"
            type="date"
            placeholder="Manufacturing Date"
            v-model="formData.date"
          >
        </div>
        <div class="col-md-4 col-12">
          <label for="colFormLabel" class="form-label">Exp date: </label>
          <input
            class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
            tabindex="1"
            type="date"
            v-model="formData.expire_date"
          >
        </div>
        <div class="col-md-4 col-12">
          <label for="colFormLabel" class="form-label">FG warehouse: </label>
          <v-select
            placeholder="Select Warehouse Location"
            v-model="formData.finished_goods_location_id"
            :options="locations"
            label="text"
            :reduce="text => text.id"
          />
        </div>
        <div class="col-md-4 col-12">
          <div class="d-flex align-items-center justify-content-between filter-label">
            <label for="colFormLabel" class="form-label">Customer Name: </label>
            <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                <i class="fas fa-search"></i>
            </span>
          </div>

          <AsyncSelect
            placeholder="Select Customer"
            v-model="contactProfile"
            :api-service="fetchContactProfiles"
            :format-label="formatPatientLabel"
            class="flex-grow-1"
            :additional-query="{type: 'customer'}"
          />
        </div>
        <div class="col-md-4 col-12">
          <div class="d-flex align-items-center justify-content-between filter-label">
            <label for="colFormLabel" class="form-label">Buyer Name: </label>
            <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                <i class="fas fa-search"></i>
            </span>
          </div>
          <AsyncSelect
            placeholder="Select Buyer"
            v-model="buyerProfile"
            :api-service="fetchContactProfiles"
            :format-label="formatPatientLabel"
            class="flex-grow-1"
            :additional-query="{type: 'buyer'}"
          />
        </div>
      </div>
    </div>

    <div class="p-2">
      <div class="row p-2">
        <ProductionComponentTable
            @checkQtyUpdate="checkProductionQty"
            :items="productionItems"/>
      </div>
      <div class="px-2 row">
        <div class="col-md-8">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a @click="activeComponentSet('material')" class="active nav-link" href="#material-tab" data-bs-toggle="tab" aria-controls="materialTab"
                 role="tab" aria-selected="true">RM & PM Consumption</a>
            </li>
            <li class="nav-item">
              <a @click="activeComponentSet('direct')" class="nav-link" href="#direct-tab" data-bs-toggle="tab" aria-controls="directTab"
                 role="tab" aria-selected="false">Labour & Direct Overhead</a>
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <v-select
              placeholder="Select RM warehouse Location"
              v-model="formData.raw_material_location_id"
              :options="locations"
              label="text"
              :reduce="text => text.id"
              @update:modelValue="checkWarehouseQty"
          />
        </div>
      </div>
      <!-- Tab Content -->
      <div class="tab-content mt-3">
        <!-- RM & PM Consumption Tab Pane -->
        <div class="tab-pane fade show active" id="material-tab" role="tabpanel" aria-labelledby="materialTab">
         <MaterialComponentTable :items="materialItems"/>
        </div>
        <!-- Labour & Direct Overhead Tab Pane -->
        <div class="tab-pane fade" id="direct-tab" role="tabpanel" aria-labelledby="directTab">
          <DirectComponentTable :items="directItems"/>
        </div>
      </div>
    </div>

    <div class="pb-5 px-2">
      <div class="d-flex flex-wrap gap-1 gy-2 justify-content-center">
        <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true)" class="btn btn-primary">
          Save
        </button>
        <button
          :disabled="saveButtonLoader || saveNewButtonLoader"
          @click="handleSubmit(false)"
          class="btn btn-primary"
        >
          Save & New
        </button>
        <button :disabled="saveButtonLoader || saveNewButtonLoader" @click="handleSubmit(true, true)" class="btn btn-primary">Save & Print</button>
        <button :disabled="productLoader" @click="navigateToListPage" class="btn btn-outline-secondary">Cancel</button>
      </div>
    </div>

    <GlobalLoader/>
    <ContactDropdownFilter
        v-if="store.state.isModalOpenThree"
        @onSearchContact="onSearchContact"
        type="supplier"
        :companyRoles="companyRoles"
    />
  </div>
</template>

<script setup>
import {computed, provide, inject, onMounted, ref, watch} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useStore} from "vuex";
import useDate from "@/services/utils/day";
import handleContact from '@/services/modules/contact'
import TitleButton from '@/components/atom/TitleButton'
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import ContactDropdownFilter from "@/components/atom/Contact/ContactDropdownFilter.vue";
import ProductionComponentTable from '@/components/molecule/company/inventory/production/ProductionComponentTable.vue'
import MaterialComponentTable from '@/components/molecule/company/inventory/production/MaterialComponentTable.vue'
import DirectComponentTable from '@/components/molecule/company/inventory/production/DirectComponentTable.vue'
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import {generateTxnNumber} from "@/services/utils/voucherNumberGenerator";
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import handlePurchase from '@/services/modules/purchase'
import handleRole from "@/services/modules/role";
import handleBusinessesLocations from '@/services/modules/businessesLocations'
import pdfPrinter from '@/services/utils/pdf/production';
import handleBarcode from "@/services/modules/barcode";
import handleCompany from "@/services/modules/company";
import {formatDate} from '@/services/utils/global';

const date = useDate();
const router = useRouter()
const route = useRoute()
const store = useStore()

const showError = inject('showError');
const showSuccess = inject('showSuccess');

const {fetchProductCosting, fetchSingleProductCosting, storeProduction, fetchBatchProductStock, fetchSingleProduction} = handlePurchase()
const {fetchBusinessLocationsList} = handleBusinessesLocations()
const {fetchContactProfiles} = handleContact()
const {formatPatientLabel} = useAsyncDropdownHelper();
const {fetchCompanyDefaultRoles} = handleRole();
const {exportToPDF} = pdfPrinter();
const {fetchBarcode} = handleBarcode();
const {fetchCompanyInfo} = handleCompany();

const companyId = computed(() => route.params.companyId)
let loader = ref(false)
let productLoader = ref(false)
let saveButtonLoader = ref(false)
let saveNewButtonLoader = ref(false)
let locations = ref([])
let prefix = ref('')
let serial = ref('')
const productCosting = ref(null);
const contactProfile = ref(null);
const buyerProfile = ref(null);
const companyRoles = ref([]);
const productionItems = ref([]);
const materialItems = ref([]);
const directItems = ref([]);
const activeItems = ref([])

const companyInfo = ref({})
const singleProduction = ref({})
const barcodeQuery = ref('')
const barcode = ref('')
const qrcode = ref('')

const formData = ref({
  company_id: companyId.value,
  product_costing_master_id: null,
  product_id: null,
  contact_profile_id: null,
  buyer_id: null,
  productCosting: null,
  status: 'active',
  batch_no: null,
  expire_date: null,
  production_quantity: 0,
  rate: null,
  finished_goods_value: null,
  date: '',
  finished_goods_location_id: null,
  raw_material_location_id: null,
  description: null
})

provide('formData', formData);

watch(productCosting, (newValue) => {
  resetForm()
  if (newValue) {
    getProductCostingDetails(newValue.id)
  }
});

const components = {
  direct: DirectComponentTable,
  material: MaterialComponentTable
}

const activeComponentSet = (type) => {
  if (type === 'direct') {
    activeItems.value = directItems.value
    checkWarehouseQty();
    return;
  }

  activeItems.value = materialItems.value
  checkWarehouseQty();
}

const getProductCostingDetails = (id) => {
  fetchSingleProductCosting(id, `?company_id=${companyId.value}`).then(({data, status, message}) => {
    if (!status) {
      return showError(message)
    }

    formData.value.product_costing_master_id = data.id
    formData.value.product_id = data.product_id

    productionItems.value = [];
    materialItems.value = [];
    directItems.value = [];
    data.general.forEach((item) => {
      const {
        id: product_costing_general_id,
        product_costingable,
        product_costingable_type,
        product_costingable_id,
        type,
        quantity,
        total_quantity,
        rate,
        amount
      } = item

      if (item.type === 'raw_materials' || item.type === 'packing_materials') {
        materialItems.value.push({
          name:product_costingable.name,
          product_costing_general_id,
          product_costingable_type,
          product_costingable_id,
          type,
          quantity,
          total_quantity
        });
      }

      if (item.type === 'direct_overhead' || item.type === 'direct_labor') {
        directItems.value.push({
          name:product_costingable.name,
          product_costing_general_id,
          product_costingable_type,
          product_costingable_id,
          type,
          quantity,
          total_quantity,
          rate: rate,
          amount
        });
      }

      if (item.type === 'production') {
        item.product_costing_general_id = item.id
        item.percentage = item.wastage
        productionItems.value.push(item);
        formData.value.production_quantity = productionItems.value[0].total_quantity
        formData.value.product_id = productionItems.value[0].product_costingable_id
      }
    });

    activeComponentSet('material')
  })
}

onMounted(async () => {
  loader.value = true
  let voucher = await generateTxnNumber(`?company_id=${companyId.value}&voucher_type=journal_voucher&txn_type=manufacturing_journal`);
  prefix.value = voucher.prefix;
  serial.value = voucher.serial;
  formData.value.date = date.currentDate();
  const companyQuery = `?company_id=${companyId.value}`
  const businessLocationRes = fetchBusinessLocationsList(companyQuery)
  Promise.all([
    fetchCompanyDefaultRoles(companyQuery).then(res => {
      if (res.data) companyRoles.value = res.data
    }),
    businessLocationRes.then(res => {
      if (res.data) locations.value = res.data
    }),
  ]).then(() => loader.value = false).catch(() => loader.value = false)
})

watch(contactProfile, (newVal) => {
  if (!newVal) {
    return formData.value.contact_profile_id = null;
  }

  formData.value.contact_profile_id = newVal.id;
})

watch(buyerProfile, (newVal) => {
  if (!newVal) {
    return formData.value.buyer_id = null;
  }

  formData.value.buyer_id = newVal.id;
})

function navigateToListPage() {
  router.push({name: 'production-list', params: route.params, query: route.query})
}

function onOpenContactSearchModal() {
  store.state.isModalOpenThree = true;
}

function onSearchContact(contact) {
  contactProfile.value = contact
}

const validateItemStock = (itemStock, item) => {
  if (!itemStock) {
    return "Product not found on this warehouse";
  }

  if (itemStock.stock < (item.total_quantity * formData.value.production_quantity)) {
    return `Product stock is less than total quantity (remaining stock ${itemStock.stock})`;
  }

  return null;
}

const checkProductionQty = (qty)=> {
  formData.value.production_quantity = qty
  checkWarehouseQty()
}

const checkWarehouseQty = () => {
  const itemIds = activeItems.value.map(item => {
    return item.product_costingable_id;
  })

  if (
    (! itemIds || (Array.isArray(itemIds) && itemIds.length === 0)) &&
    formData.value.production_quantity < 1
  ) {
    return;
  }

  const queryObject = {
    company_id: companyId.value,
    ids: itemIds
  }

  if (formData.value.raw_material_location_id) {
    queryObject.location_id = formData.value.raw_material_location_id
  }

  const queryString = new URLSearchParams(queryObject);
  let itemStocks = [];

  fetchBatchProductStock(`?${queryString}`).then(res => {
    if (res.data) {
      itemStocks = res.data
    }

    activeItems.value = activeItems.value.map(item => {
      const itemStock = itemStocks.find(is => is.id === item.product_costingable_id)
      const errorMessage = validateItemStock(itemStock, item);

      return {
        ...item,
        errorMessage: errorMessage
      }

    })

  }).catch(err => console.log(err))
}

function handleSubmit(redirect = false, print = false) {
  if (redirect) {
    saveButtonLoader.value = true
  } else {
    saveNewButtonLoader.value = true
  }

  formData.value.bill_number = prefix.value + '-' + serial.value
  formData.value.production_items = productionItems.value
  formData.value.material_items = materialItems.value
  formData.value.direct_items = directItems.value
  if (!formData.value.date) formData.value.date = date.currentDate()

  storeProduction(formData.value).then(({status, message, data}) => {
    saveButtonLoader.value = false
    saveNewButtonLoader.value = false
    if (!status) {
      return showError(message)
    }

    showSuccess(message)
    if (print) goToPrint(data.id)
    if (redirect) navigateToListPage()
    resetForm()
  }).catch(() => {
    saveButtonLoader.value = false
    saveNewButtonLoader.value = false
    productLoader.value = false
  })
}

const goToPrint = (id) => {
  const user = store.state.user.user
  const companyQuery = `?company_id=${route.params.companyId}`;

  fetchCompanyInfo(companyId.value).then((res) => {
    companyInfo.value = res.data;
  }).catch((error) => {
    console.log(error);
  });

  fetchSingleProduction(id, companyQuery).then(({status, data}) => {
    if (status) {
      singleProduction.value = data;
      let qrcodeData = JSON.stringify({
        ref_no: singleProduction.value.bill_number,
        ref_date: singleProduction.value.production_date,
        created_by: user.name,
        created_date_time: formatDate(new Date())
      })
      barcodeQuery.value = `?code=${singleProduction.value.bill_number}&qrcode=${qrcodeData}`;
    }
  }).then(() => {
    fetchBarcode(barcodeQuery.value).then((res) => {
      barcode.value = res.barcode;
      qrcode.value = res.qrcode
    }).then(() => {
      exportToPDF(companyInfo.value, singleProduction.value, barcode.value, qrcode.value, user.name)
    })
  }).catch((error) => {
    console.log(error);
  })
}

const resetForm = () => {
  productionItems.value = []
  materialItems.value = []
  directItems.value = []

  contactProfile.value = null;
  buyerProfile.value = null;
  productCosting.value = null;

  formData.value = {
    company_id: companyId.value,
    product_costing_master_id: null,
    product_id: null,
    contact_profile_id: null,
    buyer_id: null,
    productCosting: null,
    status: 'active',
    batch_no: null,
    expire_date: null,
    production_quantity: 0,
    date: date.currentDate(),
    rate: null,
    finished_goods_value: null,
    finished_goods_location_id: null,
    raw_material_location_id: null,
    description: null
  }
}

</script>
